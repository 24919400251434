import axios from "axios"
import { socket } from "../tool/socketIO"
const notiUrl = process.env.REACT_APP_HOST_API + '/notification'


export const getLatestNotification = async (sessionId, userId) => {
  if (!userId || !sessionId) {
    console.log("can't get notifications")
    return false
  }
  try {
    let res = await axios.get(notiUrl + '/' + sessionId + '/' + userId)
    // console.log(res)
    return res.data.notification
  }
  catch (err) {
    console.log(err)
    return err
  }
}
export const updateNotification = async (notification) => { 
  if (!notification) {
    console.log("can't update notification")
    return false
  }
  //console.log(user)
  try {
    let res = await axios.put(notiUrl + '/' + notification.id, {
      notification: notification,
    })
    return res.data.user
  }
  catch (err) {
    //console.log(err)
    return null
  }
}


export const subscribeToNotifications = (sessionId, userId, handleNotification) => {
  if (!sessionId || !userId) {
      console.error("Invalid sessionId or userId");
      return;
  }

  socket.emit('subscribe notifications', sessionId, userId);
  console.log("subscribed to notifications")

  const notificationHandler = (notification) => {
      console.log("Received notification:", notification);
      handleNotification(notification); 
  };

  socket.on('update notification', notificationHandler);

  return () => {
      socket.off('update notification', notificationHandler);
      socket.emit('unsubscribe notifications', sessionId, userId);
  };
};
