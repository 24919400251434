import React, { useState, useEffect, useContext } from 'react'
import { getLatestNotification, updateNotification } from '../../service/notificationService'
import { SessionContext } from '../../context/SessionContext'
import { AuthContext } from '../../context/AuthContext'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import "../../css/notification.scss"
import { TextField, Card, Modal } from "@mui/material";
import { subscribeToNotifications } from '../../service/notificationService';
import CloseIcon from '@mui/icons-material/Close';
import { showToast } from '../commonUnit/Toast'

const Notification = () => {
    const { session } = useContext(SessionContext)
    const { currentUser } = useContext(AuthContext)
    const [notification, setNotification] = useState(null)
    const [show, setShow] = useState(false) // Show notification by default
    const [helpfulness, setHelpfulness] = useState(null) 
    const [comment, setComment] = useState('')
    const [hasRated, setHasRated] = useState(false) 
    const [id, setId] = useState('')

    const handleNotification = (res) => {
        if(res && res.data ){
            const notification = res.data
        
            if (notification && notification.id && notification.id !== id && !notification.viewed) {
                setNotification(notification)
                setId(notification.id)
                showToast( "You received a new feedback from instructor!", "success" )
        
                if (notification.review) {
                    setHelpfulness(notification.review.helpfulness)
                    setComment(notification.review.optional_comments || '')
                    setHasRated(true)
                } else {
                    setHelpfulness(null)
                    setComment('')
                    setHasRated(false)
                }
            }
        }
    };

    useEffect(() => {
        if (!session || !currentUser) return;
        getInitialNotification();
        const unsubscribe = subscribeToNotifications(session.id, currentUser.id, handleNotification);
        return () => {
            unsubscribe();
        };
    }, [session, currentUser]);

    const getInitialNotification = async () => {
        const notification = await getLatestNotification(session.id, currentUser.id)
        
        if (notification && notification.id) {
            setNotification(notification)
            setId(notification.id)
    
            if (notification.review) {
                setHelpfulness(notification.review.helpfulness)
                setComment(notification.review.optional_comments || '')
                setHasRated(true)
            } 
        }
    }

    const markAsViewed = async () => { 
        const updatedNotification = {
            ...notification,
            viewed: true,
            view_time: new Date(),
        }
        updateNotification(updatedNotification)
        setNotification(updatedNotification) 
    }
    const handleOpenNotification = async () => {
        if (notification && !notification.viewed) {
            await markAsViewed()
        }
        setShow(true)
    }

    const handleSubmitRating = async () => {
        if(!helpfulness) { 
            showToast(' Please select a helpfulness option', 'warning')            
            return
         }
        const reviewData = {
            rate_time: new Date(),
            helpfulness,
            optional_comments: comment,
        }
        
        const updatedNotification = {
            ...notification,
            review: reviewData,
            rate_time: new Date(),
        }
        
        await updateNotification(updatedNotification)
        setNotification(updatedNotification) 
        
        setHasRated(true)
        showToast('Thank you for your feedback!', 'success')
    }

    const renderNotificationContent = () => {
        if (!notification) {
            return <div className="notification-container"> <div className="notification-empty">No new notification</div> </div>
        }
    
        return (
                <Card className="notification-container">
                    <div className="notification-header">
                        <p>Instructor Feedback</p>
                        {!notification.viewed && <span className="notification-dot"></span>}
                        <button 
                            onClick={() => setShow(false)}
                            className="close-button"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    
                    <div className="notification-full">
                        <span className='instructor-feedback'>{notification.content}</span>

                        { !notification.review && (
                            <div className="rating-section">
                            <div className='rating-question'>
                            Was this feedback helpful?
                            <div className="thumb-buttons">
                                <ThumbUpIcon
                                    className="thumb-up"
                                    onClick={() => setHelpfulness('up')}
                                    style={{
                                        color: helpfulness === 'up' ? '#4caf50' : '#888',
                                        cursor: 'pointer',
                                        fontSize: '1.5rem'
                                    }}
                                />
                                <ThumbDownIcon
                                    className="thumb-down"
                                    onClick={() => setHelpfulness('down')}
                                    style={{
                                        color: helpfulness === 'down' ? '#f44336' : '#888',
                                        cursor: 'pointer',
                                        fontSize: '1.5rem'
                                    }}
                                />
                            </div>
                            </div>
                            <TextField
                                label="Optional Comments"
                                multiline
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                variant="outlined"
                                style={{ width: '95%' }}
                            />
                            <div className="button-container">

                            <button 
                                onClick={handleSubmitRating}
                                className="submit-button"
                            >
                                Submit
                            </button>
                            </div>
                        </div>)}
                    </div>
                </Card>
        )
    }

    return (
        <>
            <div className="notification-icon" onClick={() => handleOpenNotification()}>
            <button 
                onClick={() => handleOpenNotification()} 
                className="notification-button"
            >
                SHOW NOTIFICATION {notification && !notification.viewed && <span className="notification-dot"></span>}
            </button>
            </div>
            {show && (
                <Modal 
                    open={show}
                    onClose={() => setShow(false)}
                    className="notification-wrapper"
                    >
                            {renderNotificationContent()}
                </Modal>
            )} 
        </>
    )
}

export default Notification